/* You can add global styles to this file, and also import other style files */

@import "./assets/theme/colors";
@import "./assets/theme/functions";

router-outlet~* {
    position: absolute;
    width: 100%;
    // height: 100%;
    overflow: auto;
    // height: 72%;
    height: calc(100vh - 14.2vh);
}

* {
    font-family: "Ubuntu", sans-serif;
}

// .container {
//   margin: 0 !important;
// }
// html, body { height: 100%; }
html,
body {
    max-height: 600px !important;
    margin: 0;
    padding: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

@media (max-width: 320px) {
    .content {
        width: 100%;
        text-align: center;
        margin-left: 0% !important;
    }
}

@media (max-width: 480px) {
    .content {
        width: 98%;
        text-align: center;
        margin-left: 1% !important;
    }

    router-outlet~* {
        height: calc(100vh - 16.2vh);
    }
}

@media (max-width: 600px) {
    .content {
        width: 90%;
        text-align: center;
        margin-left: 5% !important;
    }
}

@media (max-width: 801px) {
    .content {
        width: 85%;
        text-align: center;
        margin-left: 7.5% !important;
    }
}

@media (max-width: 1025px) {
    .content {
        width: 80%;
        text-align: center;
        margin-left: 10% !important;
    }
}

@media (max-width: 1281px) {
    .content {
        width: 75%;
        text-align: center;
        margin-left: 12.5% !important;
    }
}

@media (max-width: 1537px) {
    .content {
        width: 75%;
        text-align: center;
        margin-left: 12.5% !important;
    }
}

@media (max-width: 1707px) {
    .content {
        width: 75%;
        text-align: center;
        margin-left: 12.5% !important;
    }
}

.custom-dialog-container .mat-dialog-container {
    padding-top: 0px;
    padding-bottom: 38px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.mat-dialog-container {
    background: white !important;
    border-radius: 0px !important;
    padding: 0px !important;
}

#toast-container * {
    box-sizing: border-box;
    font-size: 12px;
}

.mat-form-field-infix {
    padding: 2px !important;
    // margin-top: 3px !important;
    // border-top: 0px !important;
}

.mat-form-field-underline {
    display: none;
}

#text_align {
    text-align: left !important;
    padding-left: 4px !important;
}

#number_align {
    text-align: right !important;
    padding-right: 4px !important;
}

.custom-container .mat-dialog-container {
    border-radius: 6px !important;
    border: solid #ad9b9b 2px;
    border-right-style: none;
}

.mat-dialog-container {
    border-radius: 4px !important;
}

.toast-error .md-toast-content {
    background-color: rgb(102, 187, 106) !important;
}

.toast-info .md-toast-content {
    background-color: rgb(41, 182, 246) !important;
}

.toast-error .md-toast-content {
    background-color: rgb(239, 83, 80) !important;
}

body.dialog-open {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
}

.panel {
    border: none !important;
}

.store-dialog-container {
    border-radius: 16px !important;
    // padding: 20px !important;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%) !important;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2) !important;
}

.store-dialog-container .mat-dialog-container {
    background: none !important;
    box-shadow: none !important;
    overflow: visible !important;
}

.pin-dialog-container .mat-dialog-container {
    padding-top: 0px;
    padding-bottom: 10px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%) !important;
}

.mat-menu-content {
    padding: 0px !important;
}

.mat-form-field-infix {
    width: auto !important;
}

.mat-option-text {
    height: 100%;
}

.mat-option.mat-active {
    background: white !important;
}


/* Importing Bootstrap SCSS file. */

.sample-slider{
    width:100%;
}
.sample-slider img{
    width: 100%;
}
.sample-slider .swiper-wrapper{
    transition-timing-function: linear;
}