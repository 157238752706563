@import url("https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/css/bootstrap.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/fontawesome.min.css");
@import "../src/assets/fonts/flaticons/flaticon.css";
@import "../src/assets/fonts/glyphicons/bootstrap-glyphicons.css";
@import "../src/assets/styles/plugins.css";

body {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    color: #606060;
    height: 100% !important;
}

p {
    /* font-family: "Open Sans"; */
    font-family: "Paytone", serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}


/* Headings */

h1 {
    font-size: 62px;
    font-family: "Paytone One";
    line-height: 64px;
    font-weight: 700;
    /* text-transform: uppercase; */
    margin-bottom: 20px;
    margin-top: 0px;
}

h1 span {
    font-size: 35px;
    line-height: 46px;
    font-weight: 400;
    text-transform: none;
    font-family: "Paytone One";
}

h2 {
    font-size: 36px;
    font-family: "Paytone One";
    text-transform: uppercase;
    text-align: center;
    margin-top: 0px;
    color: #222;
    letter-spacing: 0.5px;
}

h3 {
    font-family: "Paytone One", sans-serif;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.2px;
    margin-top: 0px;
    position: relative;
    margin-bottom: 20px;
}

h4 {
    font-family: "Paytone One", serif;
    font-size: 32px;
    color: #222;
    letter-spacing: 0.4px;
    margin-bottom: 30px;
    line-height: 34px;
    margin-top: 0px;
}

h5 {
    font-size: 24px;
    margin: 0;
    font-family: "Paytone One", serif;
    margin-bottom: 20px;
    color: #323232;
    text-transform: uppercase;
    letter-spacing: 0.3px;
}

h6 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    letter-spacing: 0.5px;
    padding: 5px 10px;
}

.h6_index {
    font-size: 18px !important;
    line-height: 24px !important;
    margin-bottom: 20px !important;
    text-transform: uppercase !important;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 700 !important;
    letter-spacing: 0.5px !important;
    padding: 5px 10px !important;
}

.h2_index {
    font: 500 36px / 36px Paytone One !important;
    letter-spacing: 0.5px !important;
    margin: 0 0 0px !important;
}

.h3_index {
    font: 500 36px / 44px Paytone One !important;
    letter-spacing: 0.2px !important;
    margin: 0 0 20px !important;
}

.h4_index {
    font: 500 32px / 34px Paytone One !important;
    letter-spacing: 0.4px !important;
    margin: 0 0 30px !important;
}

.h4_index_delivery {
    font: 500 13px / 16px Paytone One !important;
    letter-spacing: 0.4px !important;
    margin: 0 0 20px !important;
}

.h5_index {
    font: 500 24px / 10px Paytone One !important;
    letter-spacing: 0.3px !important;
    margin: 0 0 20px !important;
}

.p_index {
    line-height: 30px !important;
    font-size: 15px !important;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    text-transform: none;
    font-family: "Gloria Hallelujah", sans-serif;
}


/* Links */

a {
    font-weight: 600;
    transition: color 0.2s ease-in-out;
}

a:hover,
a:focus {
    text-decoration: none;
}


/* Others */

.subtitle {
    font-size: 17px;
    line-height: 26px;
}

.text-light {
    color: #fff;
}


/*===========================
  Preloader
============================ */

#loading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 999999;
    margin-top: 0px;
    top: 0px;
}

#loading-center {
    width: 100%;
    height: 100%;
    position: relative;
}

#loading-center-absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    margin-top: -75px;
    margin-left: -55px;
}

.object-load {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 20px;
    margin-top: 65px;
    border-radius: 50% 50% 50% 50%;
}

#object_one {
    animation: object_one 1.5s infinite;
}

#object_two {
    animation: object_two 1.5s infinite;
    animation-delay: 0.25s;
}

#object_three {
    animation: object_three 1.5s infinite;
    animation-delay: 0.5s;
}

@keyframes object_one {
    75% {
        transform: scale(0);
    }
}

@keyframes object_two {
    75% {
        transform: scale(0);
    }
}

@keyframes object_three {
    75% {
        transform: scale(0);
    }
}


/*===========================
  Navigation
============================ */

.navbar {
    z-index: 999;
    width: 100%;
    border: 0px;
    top: 0px;
    transition: all 0.7s ease-in-out;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.navbar-nav>li>a {
    padding: 10px 25px;
}

.navbar-custom ul.nav li a {
    font-size: 14px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    font-family: "Open Sans", sans-serif;
    background: transparent;
    letter-spacing: 0.5px;
    display: block;
    line-height: 60px;
    font-weight: 600;
    color: #fff;
}

.navbar-custom ul.nav ul.dropdown-menu {
    text-align: center;
    border: 0px;
    border-radius: 5px;
}

.navbar-custom ul.nav ul.dropdown-menu li:last-child {
    border-bottom: none;
}

.navbar-custom ul.nav ul.dropdown-menu li a {
    margin: 5px;
    line-height: 40px;
    display: inline-block;
    border-radius: 5px;
}


/* Responsive navbar  */

.navbar-toggle:focus,
.navbar-toggle:active {
    outline: 0;
}

.navbar-toggle {
    font-size: 35px;
    color: #fff;
    transition: all 0.2s ease-in-out;
    position: relative;
    float: right;
    border-radius: 50%;
    min-width: 70px;
}

.navbar-right {
    margin-right: 0px;
    height: 80px;
    line-height: 80px;
}


/*===========================
  Logo
============================ */

.navbar-brand {
    margin: auto;
    position: absolute;
    transition: all 0.2s ease-in-out;
}

.navbar-brand img {
    max-width: 230px;
    position: absolute;
    left: 0;
    right: 0;
}


/* ===========================
 Main Slider
============================ */

.slider-container {
    /* padding-top:68px; */
    overflow: hidden;
    position: relative;
    cursor: all-scroll;
}

.slider {
    z-index: 1;
    position: relative;
    height: 100%;
    min-height: 800px;
}


/*Controls */

.slider-control {
    z-index: 2;
    position: absolute;
    top: 0;
    width: 12%;
    height: 100%;
    transition: opacity 0.3s;
    opacity: 0;
}

.slider-control.inactive:hover {
    cursor: auto;
}

.slider-control:not(.inactive):hover {
    opacity: 1;
    cursor: pointer;
}

.slider-control.left {
    left: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0) 100%);
}

.slider-control.right {
    right: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.18) 100%);
}


/* Pagination */

ul.slider-pagi {
    padding-left: 0px;
}

.slider-pagi {
    position: absolute;
    z-index: 3;
    left: 50%;
    bottom: 2rem;
    transform: translateX(-50%);
    font-size: 0;
    list-style-type: none;
}

.slider-pagi__elem {
    border: 2px solid;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
    border-radius: 50%;
    cursor: pointer;
}

.slider-pagi__elem:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1.2rem;
    height: 1.2rem;
    background: #fff;
    border-radius: 50%;
    transition: transform 0.3s;
    transform: translate(-50%, -50%) scale(0);
}

.slider-pagi__elem.active:before,
.slider-pagi__elem:hover:before {
    transform: translate(-50%, -50%) scale(1);
}


/* Slider Animation */

.slider.animating {
    transition: transform 0.5s;
}

.slider.animating .slide__bg {
    transition: transform 0.5s;
}

/* .slide {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
} */

.slide.active .slide__overlay,
.slide.active .slide__text {
    opacity: 1;
    transform: translateX(0);
}

.slide__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slide__bg {
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
}

.slide__overlay {
    position: absolute;
    right: 0%;
    top: -33%;
    width: 750px;
    transition: transform 0.5s 0.5s, opacity 0.2s 0.5s;
    transform: translate3d(0%, -50%, 0);
    opacity: 1;
}

.slide__overlay path {
    fill: #fff;
    stroke: #fff;
    opacity: 1;
}


/* Slider Typography */

.slide__text {
    text-align: center;
    position: absolute;
    right: 130px;
    width: 500px;
    margin-top: 50px;
    transition: transform 0.5s 0.8s, opacity 0.5s 0.8s;
    transform: translateY(-50%);
    opacity: 0;
}


/* slide1 */

/* .slide:nth-child(1) {
    left: 0;
}

.slide:nth-child(1) .slide__bg {
    left: 0;
    background-image: url(../src/assets/img/slide1_cronut.webp);
} */


/* slide2 */

/* .slide:nth-child(2) {
    left: 100%;
}

.slide:nth-child(2) .slide__bg {
    left: -50%;
    background-image: url(../src/assets/img/slide2_franchise.webp);
} */


/* slide3 */


/* slide4 */

/* .slide:nth-child(3) {
    left: 200%;
}

.slide:nth-child(3) .slide__bg {
    left: -100%;
    background-image: url(../src/assets/img/slide3_cake.webp);
} */


/* slide5 */

/* .slide:nth-child(4) {
    left: 300%;
}

.slide:nth-child(4) .slide__bg {
    left: -150%;
    background-image: url(../src/assets/img/slide4_bread.webp);
} */


/* slide6 */

/* .slide:nth-child(5) {
    left: 400%;
} */

/* .slide:nth-child(5) .slide__bg {
    left: -200%;
    background-image: url(../src/assets/img/slide5_baked_snacks.webp);
} */


/* slide7 */

/* .slide:nth-child(6) {
    left: 500%;
}

.slide:nth-child(6) .slide__bg {
    left: -250%;
    background-image: url(../src/assets/img/slide6_cake.webp);
} */


/*===========================
  Offer
============================ */

#offer {
    background-image: url(../src/assets/img/bake_pattern.webp);
    background-position: 85%;
    background-repeat: no-repeat;
}

#offer .container {
    background-image: url(../src/assets/img/offer.webp);
    background-position: top left;
    background-repeat: no-repeat;
}


/*===========================
  Services
============================ */


/* Services boxes */

.box_icon>.icon {
    text-align: center;
    position: relative;
}

.box_icon>.icon>.image {
    position: relative;
    z-index: 2;
    margin: 0 auto !important;
    margin: 0px 60px;
    transition: all 0.3s;
    line-height: 100px;
    width: 150px;
    color: #fff;
    border-radius: 50%;
    box-shadow: 6px 6px 0px;
    background-color: white;
}

.box_icon>.icon:hover>.image {
    transform: rotate(10deg);
}

.box_icon>.icon>.info {
    margin-top: -64px;
    border-radius: 20px;
    padding: 95px 20px 40px;
    border: 6px solid #fff;
    transition: all 0.3s;
}


/* ===========================
--- About Us 
============================ */


/* Feature icons */

#about .media i {
    font-size: 90px;
    margin: 20px;
    padding: 10px;
    vertical-align: middle;
    transition: all 0.3s linear;
}

.features-icon {
    margin: 20px 0 40px;
}


/*===========================
 Testimonials
============================ */

.testimonial {
    margin: 20px 15px 0;
}

.testimonial .testimonial-pic {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    float: left;
    border: 5px solid #fff;
    position: relative;
}

.testimonial .testimonial-pic:after {
    content: "\f10d";
    font-family: fontawesome;
    width: 56px;
    height: 56px;
    line-height: 56px;
    border-radius: 50%;
    text-align: center;
    font-size: 26px;
    color: #fff;
    position: absolute;
    top: 65px;
    left: 0;
}

.testimonial .testimonial-content {
    padding: 10px;
    margin-left: 120px;
}

.testimonial .description {
    font-size: 16px;
    line-height: 28px;
}

.testimonial .testimonial-title {
    font-size: 24px;
    display: inline-block;
    font-family: "Gloria Hallelujah", cursive;
}

.testimonial .post {
    margin-top: 10px;
    font-weight: 700;
    font-size: 14px;
}


/*===========================
 Blog
============================ */


/* Blog Preview */

#blog-preview {
    background-image: url(../src/assets/img/cakepops_bg.webp);
    background-position: bottom right;
    background-repeat: no-repeat;
}


/* Blog post style */

.post-review {
    margin-top: 20px;
}

.post-comment {
    margin-top: -140px;
    padding: 10px 10px;
    line-height: 1;
    text-align: center;
    z-index: 99;
    color: #fff;
    border-radius: 0px 10px 10px 0px;
    position: absolute;
}

.post-comment li {
    list-style: none;
    font-size: 24px;
    line-height: 14px;
    font-weight: 700;
}

.post-comment li small {
    display: block;
    margin-top: 5px;
    font-size: 15px;
    text-transform: capitalize;
}

.post-comment li:after {
    content: "";
    border-bottom: 1px solid lightgray;
    margin: 10px;
    display: block;
}

.post-comment li:last-child:after {
    border-bottom: 0px none;
    margin: 0;
}

.post-comment a {
    color: #fff;
}


/* Jumbotron */

#blog-main .jumbotron {
    background: url(../src/assets/img/blogmain.webp) no-repeat;
    background-size: cover !important;
}


/*Headers */

.blog-post h3 {
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    margin-top: -20px;
    margin-bottom: 20px;
    transition: all 0.2s ease-in-out;
}

.blog-post h3 a {
    color: #fff;
    text-align: center;
}

#blog-page h6 {
    margin-bottom: 10px;
}


/* Post info */

.post-info {
    margin-bottom: 10px;
}

.post-info p {
    margin-right: 15px;
    display: inline-block;
    font-weight: 600;
}

.post-info p+p {
    line-height: 0px !important;
}

.post-info i {
    margin-right: 5px;
}


/* blog post */

.blog-post {
    transition: all 0.2s ease-in-out;
    padding: 20px;
    text-align: center;
}

#blog-main .blog-post {
    padding: 40px;
    border-top: 1px dotted #cecece;
}

#blog-main .blog-post:first-child {
    border-top: 0;
}

.blog-post i {
    margin-right: 5px;
}

.blog-post.post-main {
    text-align: left;
}

.post-main img {
    margin: 30px 0px;
}

.post-main h2 {
    text-align: left;
}


/* Blog tags */

.blog-tags a {
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 10px 0 23px;
    position: relative;
    margin: 0 10px 10px 0;
    text-decoration: none;
    -webkit-transition: color 0.2s;
}

.blog-tags a::before {
    background: #fff;
    border-radius: 5px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: "";
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 10px;
}

.post-info .blog-tags {
    margin-top: 20px;
}


/* popular posts */

.popular-posts .media {
    padding: 0px;
    text-align: left;
    border-bottom: 1px dotted #cecece;
    padding: 10px 0px;
    margin-top: 0px;
}

.popular-posts .media:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
}

.popular-posts .media h6 {
    font-size: 16px;
    line-height: 22px;
}

.popular-posts .media img {
    max-width: 80px;
}

.popular-posts .media small {
    margin-left: 10px;
}


/* Comments */

.comment-form.media {
    margin-top: 30px;
}

.comments-block {
    padding: 20px;
    margin: 30px 0px 60px 0px;
}

.comment.media-body {
    margin-right: 10px;
}

.comments-block .btn {
    padding: 5px;
}

.comments-block .media-object {
    max-width: 120px;
    float: left;
    margin-right: 10px;
}

.comment {
    margin: 40px auto 20px;
    padding: 30px;
    position: relative;
}

.comment {
    border-radius: 10px;
    border: 10px solid;
}

.nested {
    display: inline-block;
}


/* ===========================
 Menu 
============================ */

#menu h3 {
    text-align: center;
    display: inline-block;
    padding: 20px;
    color: #fff;
    margin: 0px;
    line-height: 36px;
    border-radius: 10px;
    left: -10%;
    z-index: 99;
}


/* Menu styling */

.menu-body {
    margin: 0 auto;
    padding-top: 30px;
    display: block;
    color: rgb(92, 92, 92);
}

.menu-section-title {
    font-size: 40px;
    display: block;
    font-weight: normal;
    margin: 20px 0;
    text-align: center;
}

.menu-item {
    margin: 25px 0;
    font-size: 18px;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    border: 2px solid #fff;
    transition: all 0.2s ease-in-out;
}

.menu-item:hover {
    border: 2px dotted #dadad7;
}


/* menu Name */

.menu-item-name {
    text-align: left;
    font-size: 18px;
    font-family: "Gloria Hallelujah", cursive;
    border-bottom: 1px dotted rgb(213, 213, 213);
}


/* menu Description */

.menu-item-description {
    font-size: 0.8em;
    line-height: 1.5em;
    margin-top: 10px;
    text-align: left;
}


/* menu Price */

.menu-item-price {
    float: right;
    margin-top: -32px;
    height: 60px;
    background: #fff;
    font-weight: 800;
    line-height: 50px;
    font-size: 20px;
    transition: all 0.3s;
}


/* menu Polaroid */

#menu .polaroid {
    margin-bottom: 55px;
    margin-top: -10px;
}


/* ===========================
  Call Out
============================ */

.feature {
    padding: 0px;
    position: relative;
}

.feature .image-container {
    position: absolute;
    height: 100%;
    padding: 0px;
    top: 0px;
}

.feature .image-container .background-image-holder {
    background: url("../src/assets/img/callout.webp") no-repeat center center;
    background-size: cover;
    height: 100%;
}


/* ===========================
--- Gallery
============================ */

.portfolio-item {
    position: relative;
}


/* Isotope */

.isotope-item {
    z-index: 2;
}

.isotope-hidden.isotope-item {
    z-index: 1;
}

.isotope,
.isotope .isotope-item {
    transition-duration: 0.8s;
}

.isotope-item {
    margin-right: -1px;
    backface-visibility: hidden;
}

.isotope {
    transition-property: height, width;
}

.isotope .isotope-item {
    transition-property: transform, opacity;
}


/* Gallery */

.gallery-item.selected img {
    transition: opacity 1s ease-in-out;
}

.gallery-thumb {
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 20px;
}


/* thumbnail Overlay */

.gallery-thumb .overlay-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.3s ease-out;
    border-radius: 20px;
}

.gallery-thumb:hover .overlay-mask {
    opacity: 1;
    border: 10px solid;
}


/* ===========================
--- Prices
============================ */

.package {
    border-radius: 20px;
    background: #f2f2f2;
    padding: 40px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    position: relative;
}

.package-name h5 {
    margin-top: -5px;
    margin-bottom: 10px;
}

.package-price {
    margin-top: 7px;
    font-size: 40px;
    font-family: "Paytone One";
}

.package ul {
    list-style: none;
    padding: 0;
    margin-top: 29px;
}

.package li {
    margin-bottom: 15px;
    font-size: 16px;
}

.package li::before {
    font-family: "Flaticon";
    content: "\f123";
    font-size: 1.5rem;
    margin-right: 3px;
}

.featured-package {
    margin-top: -30px;
    border: 2px dashed;
    background-image: url("../src/assets/img/pricetable1.webp");
    background-position: bottom right;
    background-repeat: no-repeat;
}


/*===========================
 Promo
============================ */

#promo {
    background-image: url(../src/assets/img/bgpromo.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px 0px;
    background-attachment: fixed;
}


/*===========================
  Team
============================ */

.team-member {
    text-align: center;
    line-height: 1.4em;
    padding: 5px;
}

.team-member .profile-image {
    display: inline-block;
    width: 220px;
    z-index: 1;
    position: relative;
    padding: 10px;
    border: 3px solid;
    border-radius: 50%;
}

.team-member .profile-image img {
    max-width: 100%;
    vertical-align: top;
    border-radius: 50%;
}

.team-member .team-caption {
    width: 100%;
    background-color: #f2f2f2;
    color: #555;
    padding: 125px 25px 25px;
    margin-top: -100px;
    display: inline-block;
    border-radius: 20px;
}

.team-member h4,
.team-member h6,
.team-member p {
    margin: 0 0 10px 0;
}


/*===========================
 Newsletter
============================ */

#newsletter {
    background-image: url(../src/assets/img/caketopbg.webp);
    background-position: bottom;
    background-repeat: repeat-x;
}


/* Mailchimp message */

#mce-error-response,
#mce-success-response {
    display: none;
    margin-top: 10px;
}


/*===========================
  Contact
============================ */

#contact .container {
    background-image: url(../src/assets/img/contact_bg.webp);
    background-position: right;
    background-repeat: no-repeat;
}

.contact-info {
    margin: 40px 0px 40px;
    padding: 0;
    list-style: none;
}

.contact-info i {
    margin-right: 7px;
    padding-right: 5px;
}

.contact-info li {
    display: inline-block;
    margin: 0 10px;
    font-size: 18px;
    font-weight: 700;
}


/*Map */

#map-canvas {
    max-width: 100%;
    height: 400px;
}


/*===========================
  Footer
============================ */

footer {
    padding: 50px 10px;
    background-image: url(../src/assets/img/border.webp);
    background-position: bottom;
    background-repeat: repeat-x;
}

footer a {
    color: #fff;
    font-weight: 700;
}

footer img {
    max-width: 280px;
}

.margin-footer {
    margin-top: 30px;
}


/* Go to top icon */

.back-to-top {
    /* display: none; */
    position: fixed;
    bottom: 30px;
    right: 8px;
    z-index: 119;
}

.back-to-top i {
    display: block;
    width: 44px;
    border-radius: 50%;
    height: 44px;
    line-height: 40px;
    font-size: 22px;
    color: #fff;
    text-align: center;
    transition: all 0.2s ease-in-out;
    box-shadow: 6px 6px 0px rgba(103, 102, 102, 0.2);
}


/*===========================
  Miscellaneous
============================ */


/* Parallax objects */

.parallax-object {
    position: absolute;
    z-index: 99;
}


/* Diagonal divider */

.slanted-divider {
    z-index: 1;
    display: block;
    float: left;
    height: 100px;
    margin-top: -50px;
    width: 100%;
    background: inherit;
    transform: skew(0deg, 3deg);
}


/* Owl-carousel */

.owl-carousel .owl-item {
    perspective: 1000px;
}

.owl-prev,
.owl-next {
    position: absolute;
    top: 50%;
    text-align: center;
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    box-shadow: 3px 3px 0px rgba(103, 102, 102, 0.2);
}

.owl-prev {
    left: -10px;
}

.owl-next {
    right: -10px;
}

.owl-prev:hover,
.owl-next:hover {
    transition: all 0.3s ease-in-out;
}

.owl-prev i,
.owl-next i {
    color: #fff;
    font-size: 14px;
    vertical-align: middle;
}

.owl-carousel.owl-loaded {
    display: block;
    clear: both;
    /* Important fix */
}

.owl-dots {
    text-align: center;
    margin-top: 20px;
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
}

.owl-theme .owl-dots .owl-dot span {
    border: 3px solid #cecece;
    transition: all 0.3s ease-in-out;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    color: #000;
}


/* Sections */

section .container,
section .container-fluid {
    padding-top: 20px;
    padding-bottom: 80px;
}

section {
    background: #fff;
}

.color-section,
.color-section h1,
.color-section h2,
.color-section h3,
.color-section h4,
.color-section h5,
.color-section h6 {
    color: #fff;
}


/* Sections heading */

.section-heading {
    text-align: center;
    z-index: 99;
    margin: 0px auto 100px;
}


/* Img Shapes */

.img-circle.border {
    border: 5px solid;
}

.img-rounded {
    border-radius: 10px;
}

.img-paragraph {
    margin: 0px 25px 10px 0px;
}

.img-curved {
    border-radius: 30px 10px;
}


/* Image zoom effect */

.img-zoom {
    overflow: hidden;
}

.img-zoom img {
    transition: opacity 0.7s, transform 2.5s;
}

.img-zoom:hover img {
    transform: scale3d(1.2, 1.2, 1);
}


/* Box */

.box {
    padding: 20px;
    border-radius: 10px;
    color: #fff;
    background-image: url(../src/assets/img/border.webp), url(../src/assets/img/border.webp);
    background-position: top, bottom;
    background-repeat: repeat-x;
}


/* Dropdown */

.dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #fff;
    white-space: nowrap;
    border-radius: 0px;
    transition: all 0.2s ease-in-out;
}

.dropdown-menu {
    font-size: 16px;
    border: 0px;
    border-radius: 10px;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    transition: all 0.2s ease-in-out;
}


/* Pagination */

.pagination>li>a,
.pagination>li>span {
    margin: 0 5px;
    border: 0;
    margin-top: 20px;
    font-weight: 600;
    color: #fff;
    border-radius: 50% !important;
    transition: all 0.2s ease-in-out;
}

.pagination>li>a:hover,
.pagination>li>a:focus {
    color: #fff;
}


/* Alerts */

.alert {
    padding: 25px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 10px;
    font-size: 16px;
}


/* Breadcrumbs */

.breadcrumb {
    margin-bottom: 0px;
    list-style: none;
    margin: 0px 10px 0px 10px;
    background: none;
    text-align: center;
    display: inline-block;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
}

.breadcrumb .active {
    font-weight: 900;
}

.breadcrumb a {
    color: #cecece;
}

.breadcrumb>li+li:before {
    padding: 0 10px;
    color: #fff;
    font-family: Fontawesome;
    content: "\f101";
}


/* Labels */

.label {
    padding: 0.2em 0.6em 0.3em;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
    color: #fff;
    border-radius: 10px;
    font-family: "Gloria Hallelujah";
}


/* Progress bar */

.progress-bar {
    height: 10px;
    box-shadow: 6px 6px 0px rgba(103, 102, 102, 0.2);
}

.progress {
    box-shadow: none;
}


/* fade tab transition */

.fade {
    opacity: 0;
    transition: opacity 0.55s linear;
}


/* Pager */

.pager li>a,
.pager li>span {
    display: inline-block;
    padding: 10px 24px;
    font-size: 18px;
    color: #fff;
    border: 1px solid;
    font-family: "Open Sans", sans-serif;
    border-radius: 0px;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
}

.pager li>a:hover {
    color: #fff;
}


/* tab container */

.tab-content {
    padding: 80px 60px;
    border-radius: 10px;
    border: 5px solid;
}

.tab-content.small {
    padding: 20px;
}

.tab-content.white {
    background: #fff;
}


/* Nav-tabs */

.nav-tabs {
    text-align: center;
}

.nav.nav-tabs>li.active>a:hover,
.nav.nav-tabs>li.active>a:focus,
.nav.nav-tabs>li>a:hover,
.nav.nav-tabs>li.active>a {
    color: #fff;
}

.nav.nav-tabs>li {
    float: none;
    display: inline-block;
    padding: 0px 10px;
    border: 0px;
}

.nav.nav-tabs>li>a {
    position: relative;
    display: block;
    letter-spacing: 0.2px;
    min-height: 50px;
    line-height: 57px;
    border: 0px !important;
    color: #333;
    background: #fff;
    font-family: Open Sans;
    font-weight: 700;
    padding: 10px 35px;
    font-size: 20px;
    border-radius: 10px 10px 0 0px;
    transition: all 0.2s ease-in-out;
}

.nav.nav-tabs.small>li {
    padding: 0px;
}

.nav.nav-tabs.small>li>a {
    letter-spacing: 0px;
    display: block;
    min-height: 20px;
    line-height: 27px;
    padding: 10px;
    font-size: 15px;
    border-radius: 10px 10px 0 0px;
    transition: all 0.2s ease-in-out;
}


/* Elements page*/

.glyph {
    font-size: 40px;
    display: inline-block;
}


/* No padding elements */

.no-padding {
    padding: 0px;
    width: 100%;
}


/* Panels */

.panel {
    border: 0;
    background: transparent;
}

.panel-title {
    font-size: 18px !important;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border: 0;
}

.panel-heading a {
    font-weight: normal;
    display: block;
    text-transform: none;
    padding: 10px;
    border: 0px;
    border-radius: 0px;
}

.panel-heading a.collapsed {
    color: #333 !important;
}

.panel-group {
    padding: 20px;
    border-radius: 10px;
}

.panel-group .panel+.panel {
    margin-top: 0px;
    background: #fdf5e7;
}

.panel-group .panel-heading+.panel-collapse>.panel-body {
    border-top: 0px;
}

.panel-heading {
    padding: 0px;
    margin-bottom: 10px;
    color: #2dbba4;
}

.panel-heading [data-toggle="collapse"]:after {
    content: "\f123";
    font-family: Flaticon;
    float: right;
    font-size: 18px;
    line-height: 22px;
    transition: all 0.2s ease-in-out;
}

.panel-heading [data-toggle="collapse"].collapsed:after {
    transform: rotate(40deg);
}


/* Lists */

ul {
    line-height: 30px;
}

ul.no-margin {
    margin: 20px;
    padding: 0;
}


/* Buttons */

.btn {
    padding: 0;
    position: relative;
    padding: 18px 25px;
    font-weight: 700;
    font-size: 14px;
    font-family: Open Sans;
    text-align: center;
    transition: all 0.2s ease-in-out;
    background: none;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    margin-top: 20px;
    color: #fff;
}

.btn_index {
    padding: 18px 25px !important;
}

.btn-line {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
}


/*Button Line */

.btn:hover {
    color: #fff;
}

.btn:hover .btn-line {
    transform: translate(5px, 5px);
}

.btn:hover .btn-line-shift {
    transform: translate(-5px, -5px);
}

.btn-group.open .dropdown-toggle {
    box-shadow: none;
}


/* Hr */

hr {
    border-top: 2px dotted #cecece;
}

.hr {
    max-width: 150px;
    padding: 0;
    border-style: dotted;
    border-width: 1px 0 0 0;
    text-align: center;
    clear: left;
    display: block;
    height: 0;
    margin: 20px auto 0px auto;
    width: 100%;
}

.hr:before {
    content: "\f111";
    font-family: "Flaticon";
    font-weight: normal;
    position: relative;
    top: -0.7em;
    font-size: 25px;
    padding: 0.4em 0.4em;
}


/* Forms */

.form-control {
    font-size: 16px;
    background: #fff;
    box-shadow: none;
    border-radius: 8px;
    margin-top: 15px;
    border: 2px dotted;
    height: 40px;
}

.input-group-btn:last-child>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-btn .btn {
    margin-top: -1px;
}

.input-group-btn span,
.btn span {
    color: #fff;
}

.form-control.form-control.input-lg {
    margin-top: -1px;
    height: 56px;
}

.form-control:focus,
.textarea-field:focus,
.input-field:focus {
    outline: 0 none;
    border: 2px solid;
}

textarea {
    height: 150px;
    resize: none;
}

textarea.form-control:focus {
    outline: 0 none;
}


/* BIG Social Media Icons */

.social-media {
    width: 100%;
    text-align: center;
}

.social-media i {
    font-size: 22px;
    border: 3px solid;
    color: #ffffff;
    width: 55px;
    margin: 5px;
    height: 55px;
    line-height: 50px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    transition: all 0.5s;
}

.social-media i:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    border-radius: 50%;
    transition: all 0.5s;
    transform: scale(1.1);
    border: 3px solid;
}

.social-media i:hover:after {
    transform: scale(0);
    opacity: 0;
}


/* Small Social Media Icons */

.smaller.social-media i {
    font-size: 16px;
    transition: 0.3s;
    margin: 0px;
    width: 35px;
    line-height: 30px;
    height: 35px;
    border-radius: 50%;
    padding: 0;
    color: #fff;
}

.smaller.social-media {
    margin-top: 10px;
    margin-bottom: 10px;
}


/* Centered columns */

.col-centered {
    float: none;
    margin: 0 auto;
}


/* Blockquote */

blockquote {
    padding: 30px;
    margin: 0 0 20px;
    font-size: 18px;
    line-height: 28px;
    border-left: 0;
    font-weight: 700;
    border-radius: 20px;
    font-family: "Gloria Hallelujah";
}

blockquote:before {
    content: "\f10d";
    font-family: "Fontawesome";
    font-size: 1.6em;
    line-height: 0.1em;
    margin-right: 0.5em;
    vertical-align: 0.2em;
}

.blockquote-reverse,
blockquote.pull-right {
    border-right: 0;
}


/* Polaroid image*/

.polaroid {
    position: relative;
    width: 100%;
    transform: rotate(-2deg);
}

.polaroid img {
    border: 10px solid #fff;
    border-bottom: 65px solid #fff;
    box-shadow: 0px 10px 40px 0px #d0cdcd;
    -webkit-backface-visibility: hidden;
    /* chrome fix */
}

.polaroid p {
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 20px;
    bottom: 10px;
    font-family: "Gloria Hallelujah", cursive;
}


/* Nav-pills */

.nav-pills {
    display: inline-block;
    margin-bottom: 20px;
}

.nav-pills>li>a {
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus,
.nav-pills>li>a:hover,
.nav-pills>li>a,
.nav-pills .nav>li>a:hover {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin: 8px;
    padding: 10px 15px;
    font-family: Open Sans;
}


/* Tables */

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
}

.table>tbody>tr:first-child>td {
    border: none;
}


/* Wells */

.well {
    border-radius: 10px;
    padding: 40px;
    box-shadow: 6px 6px 0px rgba(103, 102, 102, 0.2);
}

.well .list-unstyled {
    list-style: none;
    line-height: 36px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
}


/* Media */

.media {
    margin-top: 45px;
    padding: 20px;
}

.media-heading small {
    font-weight: 700;
    display: block;
    margin: 10px 0 20px;
    line-height: 20px;
}


/* Margins */

.margin1 {
    margin-top: 80px;
}


/* Jumbotron */

.jumbotron {
    background-size: cover !important;
    background-position: center center !important;
    height: 300px;
    color: #fff;
    margin-top: 5em;
}


/* page title*/

.page-title {
    background-image: url("../src/assets/img/caketopbg.webp") no-repeat;
    background-position: right, left;
    background-repeat: no-repeat;
    color: #fff;
    padding: 20px 30px;
    bottom: 0px;
    text-align: center;
    margin-top: -50px;
}

.page-title h1 {
    color: #fff;
    margin-bottom: 5px;
}


/* Sidebar */

.sidebar {
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.sidebar .well {
    border-radius: 10px;
    padding: 20px;
    box-shadow: none;
    border: none;
}


/* List group */

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    transition: all 0.6s;
}

.list-group-item:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 0px;
}

a.list-group-item,
button.list-group-item {
    color: #fff;
}

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
    color: #fff;
    text-decoration: none;
}




/*
Template Name: Piece of Cake
Color style: Sweet
*/


/*________ Pink ________  */

.loader span,
.btn,
.btn-primary,
.navbar-custom ul.nav ul.dropdown-menu,
.label-default,
.list-group-item,
.owl-prev:hover,
.owl-next:hover,
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span,
.pager li>a,
.pager li>span,
.pagination>li>a:hover,
.pagination>li>a:focus,
.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover,
.testimonial .testimonial-pic:after,
.nav-pills>li.active>a,
.nav-pills>li>a:hover,
.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover,
.nav-pills.nav>li>a:focus,
.navbar,
.social-media i:after,
.back-to-top i,
#menu h3,
.object-load,
.blog-tags a {
    background-color: #d0324b;
}

a,
#contact i,
.btn-line,
#about .media i,
.social-media i:hover,
.social-media i:after,
.back-to-top i:hover,
.menu-item-name,
.post-comment a:hover,
.navbar-custom ul.nav li a:hover,
.navbar-custom .nav>.active>a,
h3 {
    color: #d0324b;
}

.gallery-thumb:hover .overlay-mask,
.img-circle.border,
.section-heading,
.box_icon:hover .icon>.info,
.social-media i,
.color-section .form-control,
.featured-package:hover {
    border-color: #d0324b;
}


/*________  Green ________  */

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover,
.navbar-toggle,
.nav-pills>li>a,
.nav.nav-tabs>li.active>a:hover,
.nav.nav-tabs>li.active>a:focus,
.nav.nav-tabs>li>a:hover,
.nav.nav-tabs>li.active>a,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover,
.navbar-custom ul.nav ul.dropdown-menu li a,
.nav-pills .nav>li>a:focus,
.btn:hover,
.btn:focus,
.pager li>a:hover,
.pager li>a:focus,
.btn-primary:hover,
.btn-primary:focus,
.page-title,
.pagination>li>a,
.pagination>li>span,
.color-section,
.owl-prev,
.owl-next,
.box,
.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover,
.table-striped,
.dropdown-menu,
.tab-content,
.nav.nav-tabs>li>a:hover,
.nav.nav-tabs>li.active>a:hover,
.nav.nav-tabs>li.active>a:focus,
.nav.nav-tabs>li.active>a,
.post-comment,
.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    background-color: #2dbba4;
}

h1,
#about .media i:hover,
footer i,
.post-info i,
.gallery-thumb i:hover,
a:hover,
a:focus,
h6:before,
.hr:before,
.hr,
.post-slide .post-title>a:hover,
.package li::before,
.package-price,
ul.social-list li a,
blockquote:before,
.box_icon>.icon>.image,
.panel-heading a:hover,
.panel-heading a:focus,
.panel-heading .panel-heading a {
    color: #2dbba4;
}

.team-member .profile-image,
.btn:hover,
.btn:focus,
.header-text,
.navbar,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover,
.form-control,
.form-control:focus,
.textarea-field:focus,
.input-field:focus,
.featured-package,
.slider-pagi__elem,
.navbar-custom ul.nav li a:hover,
.navbar-custom .nav>.active>a,
.color-section h2,
.tab-content {
    border-color: #2dbba4;
}


/*________  Light Yellow ________  */

.color-section2,
.tab-content,
.panel-group,
.post-slide,
.navbar-custom ul.nav li a:hover,
.navbar-custom .nav>.active>a,
.navbar-custom ul.nav ul.dropdown-menu li a:hover,
.textarea-field:focus,
.input-field:focus,
.back-to-top i:hover,
.well,
.featured-package,
.package:hover,
.box_icon>.icon>.info,
#loading {
    background-color: #fdf5e7;
}

.breadcrumb>.active,
.breadcrumb a:hover,
.breadcrumb a:focus,
.color-section .hr,
.color-section .hr:before,
.testimonial .testimonial-title,
.color-section a:hover {
    color: #fdf5e7;
}

h2,
.well,
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th,
.comment {
    border-color: #fdf5e7;
}

#contactRow {
    display: inline;
}


/*_________ Plugins CSS_________*/


/* Owl carousel */


/* 
 *  Owl Carousel - Animate Plugin
 */

.owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
    z-index: 0;
}

.owl-carousel .owl-animated-out {
    z-index: 1;
}

.owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


/* 
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
    -webkit-transition: height 500ms ease-in-out;
    -moz-transition: height 500ms ease-in-out;
    -ms-transition: height 500ms ease-in-out;
    -o-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
}


/* 
 *  Core Owl Carousel CSS File
 */

.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel.owl-loaded {
    display: block;
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block;
}

.owl-carousel.owl-hidden {
    opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
    display: none;
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel .owl-item img {
    display: block;
    -webkit-transform-style: preserve-3d;
}

.owl-carousel.owl-text-select-on .owl-item {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
}

.owl-carousel .owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
}

.owl-carousel.owl-rtl {
    direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
    float: right;
}


/* No Js */

.no-js .owl-carousel {
    display: block;
}


/* 
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    -webkit-transition: opacity 400ms ease;
    -moz-transition: opacity 400ms ease;
    -ms-transition: opacity 400ms ease;
    -o-transition: opacity 400ms ease;
    transition: opacity 400ms ease;
}

.owl-carousel .owl-item img {
    transform-style: preserve-3d;
}


/* 
 * 	Owl Carousel - Video Plugin
 */

.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
}

.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    /* background: url("owl.video.play.webp") no-repeat; */
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -webkit-transition: scale 100ms ease;
    -moz-transition: scale 100ms ease;
    -ms-transition: scale 100ms ease;
    -o-transition: scale 100ms ease;
    transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
    -webkit-transition: scale(1.3, 1.3);
    -moz-transition: scale(1.3, 1.3);
    -ms-transition: scale(1.3, 1.3);
    -o-transition: scale(1.3, 1.3);
    transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none;
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    -webkit-transition: opacity 400ms ease;
    -moz-transition: opacity 400ms ease;
    -ms-transition: opacity 400ms ease;
    -o-transition: opacity 400ms ease;
    transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
}

.card .card-footer {
    background-color: transparent;
}

.card .card-footer .left {
    float: left;
}

.card .card-footer .right {
    float: right;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.card .card-footer .right {
    color: #d50000;
}

.card .card-footer .right a {
    color: #757575;
    margin-left: 1rem;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.card .card-footer .right a {
    color: var(--navBackground) !important;
}

.card .card-footer .right a {
    color: var(--navBackground) !important;
}

.card .card-body .card-title a {
    color: var(--navBackground) !important;
}

.img_align {
    position: absolute !important;
    top: 3px;
    right: 3px;
    width: 17px !important;
    height: 17px;
}

.visitors_text {
    color: #2dbba4;
}

.offer_align {
    /* position: absolute;
    cursor: pointer;
    top: 0.5rem;
    left: 0.5rem;
    width: 19%;
    height: 30px; */

    position: absolute;
    cursor: pointer;
    top: -6px;
    left: -8px;
    width: 40px !important;
    height: 46px;
}

.owl-carousel-div {
    margin-left: 2px;
    padding: 20px;
    padding-left: 10px;
}

@media (min-width: 320px) {
    .carousel_div {
        width: 130px !important;
    }

    .owl-carousel-div {
        margin-left: 2px;
        padding-left: 10px;
    }
}

@media (min-width: 360px) {
    .carousel_div {
        width: 170px !important;
    }
}

@media (max-width: 500px) {
    .carousel_div {
        width: 147px !important;
    }
}

@media (min-width: 600px) {
    .carousel_div {
        width: 167px !important;
    }
}

@media (min-width: 670px) {
    .carousel_div {
        width: 191px !important;
    }
}

@media (min-width: 700px) {
    .carousel_div {
        width: 200px !important;
    }
}

@media (min-width: 800px) {
    .carousel_div {
        width: 168px !important;
    }
}

@media (min-width: 900px) {
    .carousel_div {
        width: 190px !important;
    }
}

@media (min-width: 1000px) {
    .carousel_div {
        width: 217px !important;
    }
}

@media (min-width: 1025px) {
    .carousel_div {
        width: 184px !important;
    }
}

@media (min-width: 1281px) {
    .carousel_div {
        width: 235px !important;
    }
}

#font_style {
    font-size: 10px;
    margin: 0;
}

@media (min-width: 1281px) {
    #font_style {
        font-size: 11px;
    }
}

.carousel_div {
    width: 230px;
}

.p_side {
    font-size: 13px;
    line-height: 22px;
    text-align: left;
}

.splash {
    position: absolute;
    z-index: 2000;
    background: white;
    color: gray;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.splash-title {
    text-align: center;
    max-width: 500px;
    margin: 15% auto;
    padding: 20px;
}

.splash-title h1 {
    font-size: 26px;
}

.color-line {
    border-radius: 4px 4px 0 0;
}





.container {
    font-family: Arial, sans-serif;
    padding: 10px;
}

.flex_header {
    display: flex;
    justify-content: space-between;
}

.header {
    display: flex;
    justify-content: center;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    width: 49.8%;
}

.display_none_mob {
    display: block;
}

.display_none_web {
    display: none;
}

.offer {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.offer-img {
    /* width: 640px; */
    width: 100%;
    height: auto;
    border-radius: 6px;
}

.sort-filter {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.sort-filter button {
    margin: 0 5px;
    padding: 10px 20px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.categories {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    justify-items: center;
    margin: 10px 0;
}

.category-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ff4081;
    color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    width: 100%;
}

.category-item1 {
    display: flex;
    background-color: #ff4081;
    color: white;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    height: 120px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
}

.category-text {
    line-height: 25px;
}

.category-img {
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
}

.category-img1 {
    width: 70px;
    height: 70px;
}

.best-sellers,
.products {
    margin: 5px 0;
}

.h2_heading {
    text-align: center;
    font: 500 28px / 28px Paytone One !important;
    letter-spacing: 0.5px !important;
    margin: 30px 0 !important;
}

.product_image {
    padding: 10px;
}

.products-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.product-item {
    width: 250px;
    height: 200px;
    background-color: #eee;
    border: 1px solid #ccc;
}

.best-sellers-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.best-sellers-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: max-content;
}

.card-cascade {
    flex: 0 0 25%;
    /* Show exactly 4 items at a time */
    box-sizing: border-box;
    transition: transform 0.5s;
}



.img-fluid {
    width: 100% !important;
    height: auto;
}

.price_style {
    height: 30%;
    font-weight: bold;
    font-size: 14px;
    color: #e91e63;
}

.bottom-box {
    width: 150px;
    height: 80px;
    background-color: #e9ecef;
    border-radius: 50px;
    position: absolute;
    bottom: 240px;
    text-align: center;
    color: #e91e63;
    font-size: 18px;
    display: flex;
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.1882352941);
    justify-content: space-evenly;
    align-items: center;
}

.categories2 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    justify-items: center;
    margin-top: 40px;
}

.category-item2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    margin-bottom: 5px;
    text-align: center;
    background-color: #ff4081;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.category-item2 .label {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    font-family: inherit;
    white-space: inherit;
    line-height: 16px;
    color: #ff4081;
    height: 60px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.1882352941);
}

.category-img2 {
    width: 190px;
    height: 95px;
    margin-top: 40px;
}

.category-img3 {
    /* width: 190px; */
    height: 95px;
}

.noHover {
    pointer-events: none;
}

.faqs_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    background-color: #fdf5e7;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 0px;
    margin: 4px;
    margin-top: 0px;
    box-shadow: 6px 6px #67666633;
}

.faqs_p_qus {
    color: black;
    font-size: 11px;
    text-align: left;
    font-weight: bold;
}

.faqs_p_ans {
    color: black;
    font-size: 11px;
    text-align: left;
    height: 100px;
    overflow: auto;
}

.faqs_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}

.faqs_page_count {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    color: black;
    font-size: 11px;
}

.faqs_apply_now {
    margin-left: auto;
    font-size: 12px !important;
    color: #2dbba4 !important;
}

.wishlist_i {
    font-size: 17px !important;
    color: #e91e63 !important
}

.cart_i {
    font-size: 19px !important;
    color: #e91e63 !important;
}

.policy-font {
    font-size: 14px;
}

.policy_dialog {
    height: 80%;
    width: 70%;
    margin-top: 40px;
    padding: 0;
    overflow-y: scroll;
}

.popup {
    color: black;
}

.popup-inner {
    padding: 6%;
    ;
    padding-top: 4%;
    font: 14px/18px Roboto;
}

.policy_p {
    margin-top: 20px !important;
    font: 14px/18px Roboto;
    color: black;
}

.policy_b {
    font: 500 16px/22px Roboto !important;
}

.policy_ul {
    font: 14px/18px Roboto;
    list-style-type: square;
    color: black;
}

.policy_floating_btn {
    position: sticky;
    bottom: 10px;
    float: right;
    margin-right: -25px;
}

.policy_a_btn {
    text-align: center;
    text-decoration: none;
    padding: 10px 12px;
    border-radius: 60px;
    color: #ffffff;
    background: #e91e63;
    font-size: 10px;
}

.policy_divider {
    display: block;
    width: 60px;
    height: 3px;
    background: red;
    margin: 10px auto;
    margin-top: 1px;
}

.policy_h2 {
    font-size: 20px !important;
    margin-bottom: 0px !important;
    text-align: center;
    color: black !important;
}

.make-payment {
    margin-top: -10px;
    margin-bottom: 6px;
    font-size: 14px;
}


/* .swiper-button-next:after,
.swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    position: absolute;
    bottom: 200px;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    content: 'next';
    right: 70px;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    content: 'prev';
    left: 70px;
}

.swiper-pagination-custom {
    top: 230px;
    left: 0;
    width: 100%;
} */

.best-seller-swiper {
    width: 100%;
    overflow: hidden;
}

.best-seller-swiper .swiper-wrapper {
    display: flex;
}

.best-seller-slide {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card-cascade {
    flex: 0 0 100%;
    /* Show exactly 4 items at a time */
    box-sizing: border-box;
    transition: transform 0.5s;
}





/* Responsive Design */
/*===========================
  Media Queries
============================ */

/************ max width 300px *************/
@media (max-width: 300px) {

    /* Miscellaneous */
    .post-slide .post-title,
    .post-slide .post-description {
        padding: 0;
        text-align: center;
    }

    .post-comment {
        display: none;
    }

    .well {
        padding: 30px 10px;
    }

    .team-member .profile-image {
        width: 120px;
    }

    .team-member .team-caption {
        padding: 125px 5px 25px;
    }

    .label,
    .table {
        font-size: 14px;
    }

    .table table,
    .table thead,
    .tabletbody,
    .table th,
    .table td,
    .table tr {
        display: block;
    }

    td.text-left,
    td.text-right {
        text-align: center;
    }

    .owl-prev i,
    .owl-next i {
        display: none;
    }

    /* Nav-tabs */
    .nav.nav-tabs>li>a {
        min-height: 30px;
        font-size: 16px;
        padding: 15px 15px;
        line-height: 15px;
    }
}

/************ max width 480px *************/
@media (max-width: 480px) {

    /* slider */
    .slider {
        min-height: 280px;
    }

    /* menu */
    .tab-content {
        padding: 10px;
        border: 0px;
    }

    .menu-item-price {
        margin-top: 0px;
    }

    /* navbar */
    .navbar-brand {
        background: none;
        height: 50px;
        width: 60px;
    }

    .navbar-brand img {
        max-width: 140px;
        position: absolute;
        right: 0;
        top: 42%;
        margin: auto;
    }

    .navbar-toggle {
        font-size: 25px;
        color: #fff;
        transition: all 0.2s ease-in-out;
        position: relative;
        float: right;
        border-radius: 50%;
        min-width: 55px;
    }

    /* section */
    section .container {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .section-heading h2 {
        border: 0;
        line-height: 32px;
        letter-spacing: 0px;
        font-size: 29px;
    }

    .section-heading {
        margin-bottom: 3rem;
    }

    /* titles*/
    h1,
    h1 span {
        font-size: 28px !important;
        line-height: 32px;
        margin: 5px;
    }

    h2 {
        font-size: 26px;
    }

    h3 {
        font-size: 25px;
        line-height: 34px;
        text-align: center;
    }

    h4 {
        font-size: 22px;
        line-height: 24px;
    }

    h5 {
        font-size: 18px;
    }

    h6 {
        font-size: 16px;
    }

    /* diagonal divider */
    .slanted-divider {
        display: none;
    }

    /* Miscellaneous */
    .input-group-btn:last-child>.btn,
    .input-group .form-control:first-child {
        border-radius: 10px;
        margin-top: 10px;
    }

    .input-group .form-control,
    .input-group-btn {
        display: inherit;
        margin: 0 auto;
    }

    .comments-block .media-object {
        float: none;
        margin: 0 auto;
    }

    .comment {
        text-align: center;
    }

    .breadcrumb {
        line-height: 20px;
    }

    .panel-heading [data-toggle="collapse"]:after {
        display: none;
    }

    .section-heading {
        margin-bottom: 3em;
    }

    .navbar.navbar-custom.navbar-fixed-top {
        margin-bottom: 30px;
    }

    .nav-pills>li.active:after,
    .nav-pills>li.active:before {
        top: -45%;
    }

    .nav-pills>li {
        float: none;
    }

    .tab-pane h3 {
        margin-top: 10px;
    }

    .jumbotron {
        padding: 10px;
        height: 150px;
    }

    .testimonial .testimonial-pic {
        width: 100%;
        height: 100%;
    }

    .polaroid p {
        font-size: 14px;
        line-height: 18px;
    }

    .featured-package {
        background-image: none;
    }

    .testimonial {
        margin: 0;
    }

    .btn {
        font-size: 12px;
    }

    #newsletter {
        background-image: none;
    }

    .policy-font {
        font-size: 11px;
    }

    .make-payment {
        margin-top: -4px;
        margin-bottom: 0px;
        font-size: 11px;
    }

    .h2_index {
        font: 500 18px / 34px Paytone One !important;
    }

    .h3_index {
        font: 500 25px / 34px Paytone One !important;
    }

    .h4_index {
        font: 500 22px / 24px Paytone One !important;
    }

    .h4_index_delivery {
        font: 500 10px / 16px Paytone One !important;
        letter-spacing: 0.4px !important;
        margin: 0 0 20px !important;
    }

    .h5_index {
        font: 500 22px / 10px Paytone One !important;
    }

    .categories {
        grid-template-columns: repeat(5, 1fr);
        gap: 5px;
    }

    .categories2 {
        grid-template-columns: repeat(5, 1fr);
        gap: 5px;
    }

    .category-item {
        padding: 0px;
        font-size: 8px;
    }

    .category-item1 {
        height: 70px;
    }

    .bottom-box {
        width: 60px;
        height: 40px;
        bottom: 324px;
        font-size: 10px;
    }

    .category-img {
        width: 50px;
        height: 50px;
        margin-bottom: 0px;
    }

    .products-grid {
        gap: 5px;
    }

    .product-item {
        width: 70px;
        height: 70px;
    }

    .offer-img {
        /* width: 47%; */
        width: 100%;
        height: auto;
    }

    #font_style {
        font: 400 calc(14px* 0.83) / 8px Roboto, "Helvetica Neue", sans-serif;
        font-size: 8px;
    }

    .price_style {
        font-size: 10px;
    }

    .img_align {
        width: 10px !important;
        height: 10px !important;
    }

    .category-img1 {
        width: 40px;
        height: 40px;
    }

    .categories2 {
        margin-top: 20px;
    }

    .category-item2 .label {
        height: 22px;
        width: 50px;
        font-size: 6px;
        top: -12px;
        line-height: 9px;

        /* height: 30px;
        width: 50px;
        font-size: 8px;
        top: -20px; */
    }

    .category-img2 {
        width: 50px;
        height: 50px;
        margin-top: 12px;
    }

    .category-img3 {
        width: 50px;
        height: 50px;
    }

    .h2_heading {
        font: 500 18px / 14px Paytone One !important;
        margin: 20px 0 !important;
    }

    .product_image {
        padding: 4px;
    }

    .social-media i {
        width: 50px;
        height: 50px;
    }

    .offer_align {
        position: absolute;
        cursor: pointer;
        top: -3px;
        left: -5px;
        width: 24px !important;
        height: 30px;
    }

    .wishlist_i {
        font-size: 12px !important;
    }

    .cart_i {
        font-size: 14px !important;
    }

    .header {
        width: 100%;
    }

    .display_none_mob {
        display: none !important;
    }

    .display_none_web {
        display: block;
    }

    .policy_dialog {
        height: 80%;
        width: 95%;
    }

    .popup-inner {
        font: 10px / 16px Roboto;
    }

    .policy_p {
        font: 10px / 16px Roboto;
    }

    .policy_b {
        font: 500 12px / 16px Roboto !important;
    }

    .policy_ul {
        font: 10px / 16px Roboto;
    }

    .policy_floating_btn {
        bottom: 1px;
        margin-right: -16px;
    }

    .policy_h2 {
        font-size: 16px !important;
    }

    .p_index {
        line-height: 20px !important;
        font-size: 11px !important;
    }

    .category-text {
        line-height: 9px;
    }

    /* .swiper-pagination-custom {
        top: 200px;
    }

    .swiper-button-next:after, .swiper-button-prev:after {
        position: absolute;
        bottom: 150px;
    }

    .swiper-button-next:after,
    .swiper-rtl .swiper-button-prev:after {
        right: 2px;
    }

    .swiper-button-prev:after,
    .swiper-rtl .swiper-button-next:after {
        left: 2px;
    } */
}

/************ max width 567px *************/
@media (max-width: 567px) {

    .navbar-fixed-bottom .navbar-collapse,
    .navbar-fixed-top .navbar-collapse {
        max-height: 200px;
    }

    .navbar-brand img {
        left: 20px;
    }

    /* slider */
    .slider {
        min-height: 480px;
    }

    /* testimonial */
    .testimonial .testimonial-pic {
        width: 200px;
        height: 200px;
        float: none;
        margin: 0 auto;
    }

    .testimonial .testimonial-content {
        margin-left: 0px;
        text-align: center;
    }

    /* Price table */
    .package {
        margin: 20px;
    }

    /* Papeg Title */
    .page-title {
        background-image: none;
        padding: 10px;
    }
}

/************ max width 599px *************/
@media (max-width: 599px) {

    /* Services */
    .services-row {
        text-align: center;
    }

    /* Responsive margin*/
    .res-margin {
        margin-top: 30px;
    }

    /* navbar */
    .nav>li {
        text-align: center;
        font-size: 20px;
    }

    /* navbar collapse at 991px */
    .navbar-header {
        float: none;
        margin-top: -2px;
    }

    .navbar-toggle {
        display: block;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-fixed-bottom .navbar-collapse,
    .navbar-fixed-top .navbar-collapse {
        max-height: 200px;
    }

    .navbar-collapse.collapse.in {
        display: block !important;
        padding-bottom: 20px;
        max-height: 200px !important;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
    }

    .navbar-collapse::-webkit-scrollbar {
        width: 20px;
    }

    .navbar-collapse::-webkit-scrollbar-track {
        background-color: lightgray;
    }

    .navbar-collapse::-webkit-scrollbar-thumb {
        background-color: darkgray;
    }

    .navbar-nav {
        margin: 0;
        float: none !important;
        height: 100%;
    }

    .navbar-right {
        line-height: 0px;
    }

    .navbar-nav li {
        float: none;
    }

    .navbar-nav li>.dropdown-menu {
        border: 0;
        float: none;
        width: auto;
        position: static;
        box-shadow: none;
        background: inherit;
    }

    .navbar-brand-centered img {
        position: relative;
        top: 0%;
        bottom: 7px;
    }

    .navbar-brand img {
        margin-top: 5px;
    }

    /* Space between sections */
    .section-heading {
        margin-bottom: 60px;
    }

    /* Nav tabs */
    .nav-tabs>li {
        margin-bottom: 10px;
    }

    .nav.nav-tabs>li>a {
        border-radius: 10px;
    }

    /* miscelaneous */
    .margin1 {
        margin-top: 30px;
    }

    .social-media a {
        margin-top: 30px;
    }

    .owl-nav,
    .back-to-top {
        /* display: none; */
    }

    #offer,
    #offer .container,
    #contact .container {
        background-image: none;
    }

    #promo {
        background-attachment: scroll;
    }

    .timetable {
        border-left: 0px;
    }

    footer .text-left,
    footer .text-right {
        text-align: center;
    }

    #menu h3 {
        display: block;
        left: 0%;
        margin: 20px;
    }

    .menu-body {
        padding-top: 0px;
    }
}

/************ max width 768px *************/
@media (max-width: 768px) {
    .categories {
        grid-template-columns: repeat(5, 1fr);
    }

    .sort-filter {
        flex-direction: column;
        gap: 10px;
    }

    .products-grid {
        justify-content: center;
    }

    .product-item {
        width: 80px;
        height: 80px;
    }

    .offer-img {
        /* width: 550px; */
        width: 100%;
        height: auto;
        border-radius: 6px;
    }
}

/************ max width 800px *************/
@media (max-width: 800px) {

    /* Main Slider */
    .slider {
        max-height: 300px;
    }

    .slider-pagi {
        display: none;
    }

    .slide .slide__text {
        background-color: rgba(253, 253, 253, 0.76);
    }

    .slide__text {
        left: 0;
        bottom: 0;
        margin-top: 0px;
        width: 100%;
        text-align: center;
    }

    .slide__overlay {
        position: absolute;
        right: 0%;
        top: -83% !important;
        width: 350px !important;
        height: 120px;
        opacity: 1;
    }

    /*navbar */
    .navbar-nav>li>a {
        padding: 10px 20px;
    }
}

/************ max width 1400px *************/
@media (max-width: 1400px) {
    .offer-img {
        /* width: 550px; */
        width: 100%;
        height: auto;
        border-radius: 6px;
    }
}

/************ min width 1400px *************/
@media (min-width: 1400px) {
    #offer {
        background-position: 70%;
    }
}